import React, { useState } from "react"
import { Container, Row, Col } from 'react-bootstrap';
import GoogleRating from "../../googleRating/GoogleRating"
import './PropertyBanner.scss';
import ScrollAnimation from 'react-animate-on-scroll';
import PlayVideo from "../../Play/PlayVideo";
import ReactMarkdown from "react-markdown"
import Radium from 'radium';
import { bounceInRight, bounceInLeft } from 'react-animations';
import GetURL from '@Components/common/site/get-url';
import YouTube from '@u-wave/react-youtube';
import getVideoId from 'get-video-id';
const styles = {
  bounceInRight: {
    animation: 'x 2s',
    animationName: Radium.keyframes(bounceInRight, 'bounceInRight')
  },
  bounceInLeft: {
    animation: 'x 2s',
    animationName: Radium.keyframes(bounceInLeft, 'bounceInLeft')
  }
}
const PropertyBanner = (props) => {

  const [isPlaying, setPlaying] = useState(false);
  
  const [showVideo, setShowVideo] = useState(false);
  const playVideo = (video_id) => {
    // setVideoindex(video_index)   
    setShowVideo(true)
  }
  const trackerVideo = (event) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Video Play Btn',
      'formType': event,
      'formId': 'Video Play',
      'formName': 'Video',
      'formLabel': 'Video'
    });
  }
  if (props?.Embed_Video_URL) {
    var videoid = getVideoId(props?.Embed_Video_URL);
  }

  return (

    <div className="property-banner">
      <Container>
        <Row>
          <Col lg="5" className="order-lg-2">
            <ScrollAnimation className="relative" animateIn='fadeIn' animateOnce={true}>
              {showVideo && <div onClick={(e) => { setShowVideo(false) }} className="close-iframe-btn white"></div>}
              <div className="property-banner-img">
                {showVideo && props.Video_Ask ?
                  <iframe width='853' height='480' src={props.Embed_Video_URL} frameborder='0' allowfullscreen allow='xr-spatial-tracking'></iframe>
                  :
                  showVideo && !props.Video_Ask ?
                    <YouTube
                      video={videoid.id}
                      autoplay
                      onEnd={e => { setShowVideo(false) }}
                      modestBranding={1}
                      onPlaying={trackerVideo('Banner')}
                      showRelatedVideos={false}
                      showInfo={false}
                      annotations={false}

                    />
                    : null
                }
                {props.Right_Video_Image?.url &&
                  <picture>
                    <source media="(min-width:1699px)" srcset={props.Right_Video_Image?.url} />
                    <source media="(min-width:992px)" srcset={props.Right_Video_Image?.url} />
                    <source media="(min-width:768px)" srcset={props.Right_Video_Image?.url} />
                    <img className="bg-img" src={props.Right_Video_Image?.url} alt={props.Right_Video_Image?.alternativeText} />
                  </picture>
                }
                {showVideo ? null : props.Embed_Video_URL !== "" ?
                  <strong href="" className="play-btn" onClick={(e) => { playVideo(videoid.id) }}><i className="icon-play"></i></strong> :
                  null}
              </div>
            </ScrollAnimation>
          </Col>
          <Col lg="7" className="order-lg-1">
            <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
              <div className="property-banner-content">
                {/* {JSON.stringify(props)} */}
                <h1>{props.Title}</h1>
                <ReactMarkdown style={styles.bounceInLeft} source={props.Banner_Content} allowDangerousHtml />


              </div>
              <div className="property-banner-btn">
                {props.Banner_CTA_1_Label !== null && props.Banner_CTA_1_URL !== null &&

                  <GetURL label={props.Banner_CTA_1_Label} alias={props.Banner_CTA_1_URL?.Alias} class="btn btn-primary dark-bg" />

                }
                {props.Banner_CTA_2_Label !== null && props.Banner_CTA_2_URL !== null &&

                  <GetURL label={props.Banner_CTA_2_Label} alias={props.Banner_CTA_2_URL?.Alias} class="btn btn-outline--white" />
                }

              </div>
              {props.Show_Review ?
                <div className="d-none d-lg-block">
                  <GoogleRating />
                </div>
                : null}
            </ScrollAnimation>
          </Col>
        </Row>
        <PlayVideo
          isOpen={isPlaying}
          isCloseFunction={setPlaying}
          videoId={props.Embed_Video_URL}
          isAutoPlay={1}
        />
      </Container>
    </div>
  )
}

export default PropertyBanner